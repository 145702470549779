.home_main_prent_div {
  /* background-color: #eee; */
  background-color: #f8f8f8;
  padding: 1px;
}
.home-parent-div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 115px;

  padding-top: 10px;
  padding-bottom: 30px;
  max-width: 100%;
}
.child_parent_div {
  margin-top: 50px;
}
.home-child-dvi {
  text-align: left;
  margin-left: 350px;
  margin-top: 100px;
}
.carousel_div {
  width: 60%;
}

.home-banner-photo {
  max-width: 50%;
  margin: auto;
}

.home-child-img-div {
  max-width: 60%;
}
.TripShipTask-heading {
  font-size: 22px;
  font-weight: 500;

  margin-bottom: 10px;
}
.TripShipTask-p {
  font-size: 22px;
  font-weight: 500;
}
.andMakeMoney-p {
  font-size: 22px;
  font-weight: 500;
  margin-top: 10px;
}

.earn {
  background-color: #e83330;
  padding: 10px;
  position: absolute;
  margin-top: 20px;
  width: 300px;
  text-align: center;
  border-radius: 10px;
  border: none;
  font-size: 18px;
  font-weight: 500;
  color: white;
}
.download {
  border: 1px solid black;
  position: absolute;
  padding: 10px;
  margin-top: 50px;
  width: 300px;
  text-align: center;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 500;
  background-color: aliceblue;
}

.bookDownloadPage-success-div {
  max-width: 100%;
  max-height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}
.bookDownloadPage-success-div p {
  max-width: 400px;
  height: 300px;
  margin-left: 75%;
  margin-top: 50px;
  padding: 50px 15px;
  background-color: white;
  border-radius: 10px;
  position: relative;
}
.bookDownloadPage-success-p {
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
}

.Ok_Btn {
  background-color: blue;
  color: white;
  padding: 1px 10px;
  border-radius: 0px 5px 0px 0px;
  position: absolute;
  top: 0px;
  right: 0px;
}
@media screen and (max-width: 1536px) {
  .child_parent_div {
    /* background-color: red; */
  }
  .home-child-dvi {
    /* background-color: red; */
    width: 80%;
  }
}
@media screen and (max-width: 1500px) {
  .home-child-dvi {
    margin-left: 50px;
  }
}
@media screen and (max-width: 1450px) {
  .home-child-dvi {
    margin-left: 50px;
  }
}
@media screen and (max-width: 1150px) {
  .home-banner-photo {
    max-width: 60%;
  }
}
@media screen and (max-width: 1024px) {
  .home-child-dvi {
    margin-top: 50px;
  }
}

@media screen and (max-width: 983px) {
  .home-banner-photo {
    max-width: 60%;
  }
}
@media screen and (max-width: 850px) {
  .home-parent-div {
    display: grid;
    grid-template-columns: 1fr;
  }
  .child_parent_div {
    margin: auto;
  }
  .home-child-dvi {
    margin-bottom: 50px;
  }

  .carousel_div {
    margin: auto;
  }
  .home-banner-photo {
    max-width: 50%;
  }
}
@media screen and (max-width: 820px) {
  .home-child-dvi {
    margin-left: 20px;
    margin-top: 100px;
  }
}
@media screen and (max-width: 850px) {
  .home-banner-photo {
    max-width: 40%;
  }
}
@media screen and (max-width: 900px) {
  .home-child-dvi {
    margin-left: 20px;
  }
}
@media screen and (max-width: 750px) {
  .home-child-dvi {
    margin-top: 100px;
  }
  .earn {
    width: 70%;
  }
  .download {
    width: 70%;
  }
}
@media screen and (max-width: 640px) {
  .home-child-dvi {
    margin-top: 30px;
  }

  .earn {
    width: 40%;
    padding: 5px;
  }
  .download {
    width: 40%;
    padding: 5px;

    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .home-parent-div {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .home-banner-photo {
    max-width: 50%;
  }
}
@media screen and (max-width: 540px) {
  .home-child-dvi {
    margin-top: 100px;
  }

  .earn {
    width: 50%;
    padding: 10px;
    font-size: 14px;
  }
  .download {
    width: 50%;
    padding: 10px;

    margin-top: 45px;
  }
}
@media screen and (max-width: 414px) {
  .home-child-dvi {
    margin-top: 50px;
  }

  .TripShipTask-p {
    font-size: 18px;
  }
  .earn {
    width: 50%;
    padding: 10px;
  }
  .download {
    width: 50%;
    padding: 10px;

    margin-top: 50px;
  }
}
@media screen and (max-width: 412px) {
  .home-child-dvi {
    margin-top: 50px;
  }

  .earn {
    width: 50%;
    padding: 10px;
  }
  .download {
    width: 50%;
    padding: 10px;

    margin-top: 50px;
  }
}

@media screen and (max-width: 360px) {
  .earn {
    width: 50%;
  }
  .download {
    width: 50%;
  }
}
