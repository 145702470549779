.rideComford-parent-div {
  margin-top: 0px;
  /* background-color: #eee; */
  /* background-color: #f7f6f6; */
  background-color: #f8f8f8;
  padding: 50px;
}
.rideInComfort {
  font-size: 20px;
  color: rgb(1, 1, 77);
  margin-bottom: 15px;
  font-weight: 500;
}
.rideInComford-P {
  font-size: 18px;
  color: black;
}
.rodeComfort-p-first-text {
  font-size: 16px;
  color: gray;
  margin-top: 20px;
}
.rideInComfort-grid-div {
  margin-top: 40px;
}

.cart-img {
  max-width: 100%;
  margin: auto;
}
