.PhotoGallery_parent_div {
  padding: 10px;
  background-color: white;
}
.keep-heading {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 700;
  margin-top: 70px;
}
.photoGallery_flex_div {
  display: flex;
  width: 75%;
  gap: 50px;
  margin: auto;
  margin-top: 50px;
}
.dummyPhoto {
  width: 40%;
}
.photo-gallery {
  gap: 30px;
  width: 100%;
  margin: auto;
  /* border: 3px solid blue; */
  border: 3px solid #c30017;
  padding: 30px;
  height: 345px;
}
.photo-gallery-p {
  font-size: 16px;
}

.photo-cart-heading {
  font-size: 30px;
  font-weight: 700;
}
.photo-cart-p {
  color: black;
  margin-top: 10px;
  margin-bottom: 20px;
}
.read-more {
  color: rgb(2, 253, 169);
  font-weight: 700;
}

/*  */

@media screen and (max-width: 1280px) {
  .photo-gallery {
    width: 90%;
  }
}
@media screen and (max-width: 1150px) {
  .photoGallery_flex_div {
    display: grid;
    grid-template-columns: 1fr;
  }
  .dummyPhoto {
    margin: auto;
    width: 60%;
  }
}

@media screen and (max-width: 1050px) {
  .photo-gallery {
    display: grid;
    grid-template-columns: 1fr;
    width: 80%;
  }
  .photo-gallery img {
    width: 100%;
  }
}
@media screen and (max-width: 950px) {
  .photo-gallery {
    display: grid;
    grid-template-columns: 1fr;
  }
  .photo-gallery img {
    width: 100%;
  }
}
@media screen and (max-width: 700px) {
  .photo-parent-gallery-div {
    border: 3px solid #c30017;
  }
  .photoGallery_flex_div {
    width: 100%;
  }
  .photo-gallery {
    border: none;
  }
  .photo-gallery-p {
  }
}

@media screen and (max-width: 412px) {
  .photo-gallery {
    width: 100%;
  }
}
@media screen and (max-width: 381px) {
  .photo-parent-gallery-div {
    border: 3px solid #c30017;
    height: 400px;
  }
}
@media screen and (max-width: 358px) {
  .photo-parent-gallery-div {
    height: 450px;
  }
}
@media screen and (max-width: 317px) {
  .photo-parent-gallery-div {
    height: 500px;
  }
}
