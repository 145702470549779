.green_pass_div {
  text-align: left;

  padding: 20px;
}
.green_pass {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 15px;
}
.green_pass_div p {
  margin-bottom: 10px;
}

.green_pass_click_here_btn {
  background-color: rgb(203, 255, 203);
  padding: 15px 40px;
  font-weight: 500;
  border-radius: 5px;
}
