.AboutTripShipTask_parent_div {
  display: flex;
  justify-content: space-between;
}
.AboutTripShipTask_heading {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: -1050px;
  font-size: 25px;
  font-weight: 500;
}
.AboutTripShipTask_video {
  width: 560px;
  height: 315px;
  margin-left: -800px;
  margin-bottom: 50px;
}

@media screen and (max-width: 1536px) {
  .AboutTripShipTask_heading {
    margin-left: -1000px;
  }
  .AboutTripShipTask_video {
    margin-left: -750px;
  }
}
@media screen and (max-width: 1535px) {
  .AboutTripShipTask_heading {
    margin-left: -850px;
  }
  .AboutTripShipTask_video {
    margin-left: -700px;
  }
}
@media screen and (max-width: 1280px) {
  .AboutTripShipTask_heading {
    margin-left: -1000px;
  }
  .AboutTripShipTask_video {
    margin-left: -770px;
  }
}
@media screen and (max-width: 1150px) {
  .AboutTripShipTask_heading {
    margin-left: -850px;
  }
  .AboutTripShipTask_video {
    margin-left: -700px;
  }
}
@media screen and (max-width: 1050px) {
  .AboutTripShipTask_heading {
    margin-left: -700px;
  }
  .AboutTripShipTask_video {
    margin-left: -650px;
  }
}
@media screen and (max-width: 1024px) {
  .AboutTripShipTask_heading {
    margin-left: -700px;
    font-size: 16px;
    font-weight: 700;
  }
  .AboutTripShipTask_video {
    padding: 55px;
    margin-left: -600px;
  }
}
@media screen and (max-width: 1023px) {
  .AboutTripShipTask_heading {
    margin-left: -750px;
  }
  .AboutTripShipTask_video {
    margin-left: -600px;
    width: 550px;
    height: 400px;
  }
}
@media screen and (max-width: 930px) {
  .AboutTripShipTask_heading {
    margin-left: -660px;
    font-size: 20px;
  }
  .AboutTripShipTask_video {
    margin-left: -570px;
    width: 500px;
    height: 400px;
  }
}
@media screen and (max-width: 870px) {
  .AboutTripShipTask_heading {
    margin-left: -400px;
    font-size: 20px;
  }
  .AboutTripShipTask_video {
    margin-left: -350px;
    width: 500px;
    height: 400px;
  }
}
@media screen and (max-width: 767px) {
  .AboutTripShipTask_heading {
    margin-left: -210px;
    font-size: 20px;
  }
  .AboutTripShipTask_video {
    margin-left: -200px;
    height: 400px;
    width: 500px;
  }
}
@media screen and (max-width: 700px) {
  .AboutTripShipTask_heading {
    margin-left: -225px;
    font-size: 16px;
  }
  .AboutTripShipTask_video {
    margin-left: -230px;
    height: 300px;
    width: 400px;
  }
}
@media screen and (max-width: 650px) {
  .AboutTripShipTask_heading {
    margin-left: -250px;
    font-size: 14px;
  }
  .AboutTripShipTask_video {
    margin-left: -220px;
    width: 400px;
    height: 300px;
  }
}
@media screen and (max-width: 540px) and (max-height: 720px) {
  .AboutTripShipTask_heading {
    margin-left: -250px;
    font-size: 14px;
  }
  .AboutTripShipTask_video {
    margin-left: -220px;
    width: 350px;
    height: 250px;
  }
}
@media screen and (max-width: 450px) {
  .AboutTripShipTask_heading {
    margin-left: -50px;
    font-size: 14px;
  }
  .AboutTripShipTask_video {
    margin-left: -50px;
    width: 350px;
    height: 250px;
  }
}
@media screen and (max-width: 394px) {
  .AboutTripShipTask_heading {
    margin-left: -40px;
    font-size: 12px;
  }
  .AboutTripShipTask_video {
    margin-left: -50px;
    width: 300px;
    height: 300px;
  }
}
@media screen and (max-width: 355px) {
  .AboutTripShipTask_heading {
    margin-left: -30px;
    font-size: 10px;
  }
  .AboutTripShipTask_video {
    margin-left: -40px;
    width: 270px;
    height: 200px;
  }
}
@media screen and (max-width: 340px) {
  .AboutTripShipTask_heading {
    margin-left: -60px;
    font-size: 10px;
  }
  .AboutTripShipTask_video {
    margin-left: -50px;

    width: 290px;
    height: 210px;
  }
}
@media screen and (max-width: 280px) {
  .AboutTripShipTask_heading {
    margin-left: -65px;
    font-size: 9px;
  }
  .AboutTripShipTask_video {
    margin-left: -55px;
    width: 250px;
    height: 250px;
  }
}
