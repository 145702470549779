.imgCartOne-parent-div {
  /* background-color: white; */
  background-color: #fcfcfc;
  padding: 10px;
}
.imgCartOne-p {
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}
.imgCartOne {
  margin: auto;
}
.cartWithImg-parent-div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 75%;
  margin: auto;
}
.cartWithImg {
  max-width: 100%;
  margin: auto;
  margin-top: 40px;
}

.cart_grid_div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 75%;
  gap: 50px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
}
.cart_flex_div {
  display: flex;
  justify-content: center;
  gap: 20px;
  /* margin-top: 30px; */
  /* margin-right: 60px; */
  border: 1px solid #c30017;
  border-radius: 15px;
  padding: 15px;
  height: 220px;
}
.cart_text_div {
  text-align: left;
}
.cart_text_div h3 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
}
.cart_text_div p {
  line-height: 30px;
}

.img_cart_logo {
  width: 50px;
  height: 50px;
}
/* Responsiveness start */

@media screen and (max-width: 1350px) {
  .cart_grid_div {
    grid-template-columns: repeat(2, 1fr);
  }
  .cart_text_div {
    width: 100%;
  }
}

@media screen and (max-width: 950px) {
  .cartWithImg-parent-div {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 900px) {
  .cart_grid_div {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 650px) {
  .cartWithImg-parent-div {
    grid-template-columns: repeat(1, 1fr);
  }
}
