.NastedMenuBar_Bar {
  background-color: #007a9d;
  width: 50%;
  transition: all 0.4s;
}

.NastedMenuBar_top_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
}
.NastedMenuBar_howTos {
  font-size: 19px;
  color: white;
  font-weight: 500;
}
.NastedMenuBar_video_details_div {
  display: flex;
}
.NastedMenuBar_details_div {
  display: flex;
  justify-content: space-between;
}
.NastedMenuBar_details {
  text-align: left;
  padding: 10px 20px;
  color: white;
}

.NastedMenuBar_nested_Link {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 14px;
}
.NastedMenuBar_bars_icon {
  color: white;
  margin-right: 90px;
}
.NastedMenuBar_video_icon {
  color: white;
  padding: 10px 20px;
}
.NastedMenuBar_icon {
  color: white;
  padding: 10px 20px;
}
.NastedMenuBar_bars {
  display: flex;
  font-size: 25px;
  margin-left: 70px;
}
.link {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  color: white;
  /* background-color: #464646; */
}

.link:hover {
  background-color: lightskyblue;

  transition: all 0.5s;
}
.link_text {
  width: 100%;
  text-align: left;
}
.icon {
  width: 37%;
  text-align: left;
}
.icon .link_text {
  font-size: 20px;
}

@media screen and (max-width: 514px) {
  .NastedMenuBar_video_icon {
    display: none;
  }
}
@media screen and (max-width: 514px) {
  .NastedMenuBar_icon {
    display: none;
  }
}
@media screen and (max-width: 470px) {
  .NastedMenuBar_Bar {
    width: 40%;
  }
  .NastedMenuBar_howTos {
    font-size: 15px;
  }

  .NastedMenuBar_details {
    font-size: 12px;
  }

  .NastedMenuBar_nested_Link {
    font-size: 10px;
  }
}
@media screen and (max-width: 450px) {
  .NastedMenuBar_Bar {
    width: 100%;
  }
  .NastedMenuBar_howTos {
    font-size: 18px;
    color: white;
    font-weight: 500;
  }

  .NastedMenuBar_details {
    font-size: 14px;
  }

  .NastedMenuBar_nested_Link {
    font-size: 12px;
  }
}
@media screen and (max-width: 394px) {
  .NastedMenuBar_Bar {
    width: 100%;
  }
}
@media screen and (max-width: 330px) {
  .NastedMenuBar_howTos {
    font-size: 16px;
  }

  .NastedMenuBar_details {
    font-size: 13px;
  }

  .NastedMenuBar_nested_Link {
    font-size: 11px;
    margin-left: 3px;
  }
}

@media screen and (max-width: 280px) {
  .NastedMenuBar_Bar {
    width: 90%;
  }
}
