.trustedRideService-grid-div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  align-items: center;
  /* background-color: rgb(241, 250, 247); */
  background-color: white;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
}
.trustedRideService-grid-div-two {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  padding: 50px 0px;
  /* background-color: #f7f6f6; */
  background-color: #f8f8f8;
}
.first-car-img {
  display: none;
}
.second-car-img {
  max-width: 120%;
  max-height: 60%;
  margin: auto;
}
.secondCart-first-car-img {
  max-width: 50%;
  margin: auto;
}
.secondCart-second-car-img {
  display: none;
}
.trustedRideService-text-parent-div {
  max-width: 80%;
  margin-right: -50px;
  margin-left: auto;
  text-align: left;
}
.trustedRideService-text-secondCart-parent-div {
  max-width: 80%;
  margin-left: -130px;
  text-align: left;
}
.trust_heading {
  font-size: 27px;

  margin-top: -40px;
}
.trustedRide_blank_div {
  height: 2px;
  width: 100px;
  background-color: #c30017;
  margin-bottom: 50px;
  margin-top: 10px;
}
.trustedRideService-heading {
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 700;
}
.trustedRideService-first-p {
  color: black;
  font-size: 18px;
  margin-top: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.trustedRideService-second-first-p {
  color: black;
  font-size: 23px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.trustedRideService-icon-flex-div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  color: gray;
  font-weight: 500;
}
.trustedRideService-icon-parent-div {
  margin-bottom: 40px;
}
.trustedRideService-read-more {
  background-color: rgb(173, 173, 173);
  padding: 15px;
  color: black;
  font-weight: 500;
  border-radius: 20px;
}

/* Responsiveness start */

@media screen and (max-width: 1536px) {
  .trust_heading {
    margin-top: 0px;
  }

  .trustedRide_blank_div {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 1250px) {
  .first-car-img {
    display: block;
    max-width: 600px;
    margin: auto;

    margin-top: 50px;
  }
  .trustedRideService-text-parent-div {
    margin-top: 50px;
    max-width: 100%;
    padding-left: 20px;
    margin-right: 20px;
  }
  .trustedRideService-grid-div {
    display: grid;
    grid-template-columns: 1fr;
    padding-bottom: 30px;
  }
  .trustedRideService-grid-div-two {
    display: grid;
    grid-template-columns: 1fr;
    /* background-color: #c30017; */
  }
  .trust_heading {
    margin-top: 20px;
  }
  .second-car-img {
    display: none;
  }
  .secondCart-first-car-img {
    max-width: 35%;
    margin-bottom: 170px;
  }
  .trustedRideService-text-secondCart-parent-div {
    max-width: 100%;
    padding-left: 150px;
    margin-right: 20px;
    margin-top: -150px;
  }
}
@media screen and (max-width: 850px) {
  .first-car-img {
    max-width: 90%;
  }
  .trustedRideService-first-p {
  }
  .trustedRideService-text-secondCart-parent-div {
    margin-top: -120px;

    padding: 0;
    margin-left: 15px;
  }
  .secondCart-first-car-img {
    max-width: 50%;
  }
}

@media screen and (max-width: 500px) {
  .first-car-img {
    display: block;
    width: 600px;
    margin: auto;

    margin-top: 50px;
  }
  .trustedRideService-text-parent-div {
    margin-top: 200;
  }
  .trustedRideService-grid-div {
    margin-top: 100px;
  }
  .second-car-img {
    display: none;
  }
}
