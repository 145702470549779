.footer-parent-div {
  /* background-color: rgb(241, 241, 241); */
  /* background-color: #f0efef; */
  background-color: #eee;
  padding: 50px;
  color: black;
}
.media_icon {
  font-size: 20px;
}

.footer-grid-div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 75%;
  margin: auto;
}
.footer_first-single-cart-div {
  text-align: left;
  padding: 15px 0px 30px 30px;
}
.terms_footer-single-cart-div {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding-top: 30px;
}

.company_footer-single-cart-div {
  text-align: left;
  padding: 30px;

  display: flex;
  justify-content: space-between;
}
.footer-single-cart-div {
  text-align: left;
  padding-top: 30px;
}
.countries_footer-single-cart-div {
  text-align: left;
  padding: 30px;
}

.flex_div {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
.footer_border {
  border-right: 1px solid black;
  padding: 30px;
}

.footer-heading {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}
.footer-first-logo {
  width: 200px;
  height: 80px;
  margin-bottom: 20px;
}
.bangladesh {
  color: black;
}
.media_flex_div {
  display: flex;
  align-items: center;
  gap: 10px;
  color: black;
  margin-top: 10px;
}
.footer-logo-parent-grid-div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 30%;
  gap: 20px;
  text-align: center;
  margin-top: 20px;
}
.footer-child-logo-div {
  background-color: rgb(14, 59, 50);
  padding: 2px;
  width: 50px;
}
.Office-p {
  margin-top: 15px;
  font-size: 14px;
}
.envelop_icon {
  font-size: 16px;
}
.whatsApp_icon {
  font-size: 19px;
  margin-right: 5px;
  margin-left: -5px;
}
.footer-p {
  color: black;
  margin-top: 10px;
}

.footer_qr_code_div {
  display: flex;
  margin-top: 50px;
}
.footer_qrCode {
  width: 45%;
  height: 45%;
}

.footer-p_green_pass {
  color: green;
}
.greenPass {
  max-width: 70%;
  margin: auto;
  margin-top: 50px;
}
.footer-p-and-i {
  margin-top: 15px;
}
.footer-i {
  margin-right: 20px;
  color: rgb(98, 182, 165);
}
.footer-callUs-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: rgb(14, 59, 50);
  width: 150px;
  padding: 15px;
  margin-top: 20px;
}

.chatBox_div {
  text-align: right;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
.chatBox {
  font-size: 50px;
}
.xMark {
  font-size: 30px;
}
.xMark_parent_div {
  background-color: gray;
  border-radius: 5px;
  width: 40%;
  margin-left: auto;
}
.footer_welcome_div {
  background-color: aquamarine;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  margin-left: auto;
}
.footer_welcome_div h1 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.footer_welcome_div p {
  margin-bottom: 25px;
}
.input_div {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-right: 70px;
}
.footer_input_field {
  padding: 10px 25px;
  border-radius: 5px 0px 0px 5px;
}
.footer_input_btn {
  background-color: rgb(209, 209, 209);
  padding: 10px 25px;
  border-radius: 0px 5px 5px 0px;
}

@media screen and (max-width: 1024px) {
  .footer-grid-div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .footer-border-2 {
    border-right: 1px solid black;
  }
}
@media screen and (max-width: 750px) {
  .footer-parent-div {
    padding: 0;
  }
  .footer-grid-div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 95%;
  }
  .footer_border {
    border: none;
  }
  .footer-border-2 {
    border: none;
  }
  .footer_qrCode {
    width: 40%;
  }

  .terms_footer-single-cart-div {
    padding: 30px;
  }
}
@media screen and (max-width: 540px) {
  .footer_qrCode {
    width: 30%;
  }
}
