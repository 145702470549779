.safety-parent-div {
  margin-bottom: 100px;
  margin-top: 50px;
}

.safety-grid-div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  width: 85%;
  margin: auto;
}

.second-safety-img-div {
  /* background-color: red; */
}

.second-safety-img-div img {
  margin: auto;
}
.first-safety-img-div {
  display: none;
}
.safety-text-div {
  text-align: left;
}

.careers_heading {
  font-size: 18px;
  font-weight: 700;
  font-family: "Times New Roman", Times, serif;
  color: rgb(1, 120, 156);
  margin-bottom: 20px;
}

.careers-text-p {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
}

.email {
  margin-bottom: 10px;
}
.pls_wite {
  margin-bottom: 20px;
}

@media screen and (max-width: 1400px) {
  .safety-grid-div {
    width: 100%;
    padding: 20px;
  }
}
@media screen and (max-width: 1100px) {
  .safety-grid-div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .first-safety-img-div {
    display: block;
  }
  .second-safety-img-div {
    display: none;
  }
}
