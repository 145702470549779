.paymentMethod_main_div {
  background-color: white;

  padding-top: 90px;
}
.paymentMethod-parent-div {
  padding: 10px;
}
.paymentMethod-img {
  margin: auto;
  margin-bottom: 30px;
  width: 60%;
}
