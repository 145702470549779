.financial_aspects_parent_div {
  display: flex;
  margin-top: 100px;
}
.financial_aspects_content_div {
  /* background-color: red; */
  width: 400%;
  text-align: left;
  margin-top: 20px;
}
