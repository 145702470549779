.container {
  display: flex;
}
.sideBar {
  background-color: #007a9d;
  width: 300px;
  transition: all 0.4s;
}

.top_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
}
.howTos {
  font-size: 19px;
  color: white;
  font-weight: 500;
}

.bars_icon {
  color: white;
  margin-right: 90px;
}
.icon {
  color: white;
  padding: 10px 20px;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 70px;
}
.link {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  color: white;
  /* background-color: #464646; */
}

.link:hover {
  background-color: lightskyblue;

  transition: all 0.5s;
}
.link_text {
  width: 100%;
  text-align: left;
}
.icon {
  width: 37%;
  text-align: left;
}
.icon .link_text {
  font-size: 20px;
}

.details_div {
  display: flex;

  justify-content: space-between;
}
.details {
  text-align: left;
  padding: 10px 20px;
  color: white;
}
.nested_Link {
  margin-top: 20px;
  margin-left: 20px;
}
