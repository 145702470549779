.biggerInSize-parent-div {
  margin-top: 50px;
  background-color: white;
  padding: 100px;
}

.biggerINSize-text-div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  max-width: 70%;
  margin: auto;
}
.download_tripShipTask_parent_div {
  margin: auto;
}
.Join_the {
  font-size: 27px;
  margin-bottom: 15px;
  text-align: left;
}
.Connect_with {
  font-size: 27px;
  margin-bottom: 15px;

  text-align: left;
}

.download_tripShipTask {
  font-size: 27px;
  font-weight: 500;
  text-align: left;
}
.app-btn {
  width: 150px;
  height: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.qr-code-img-div {
  display: flex;
  align-items: end;
  gap: 5px;
}
/* .i_div {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 65px;
} */

.i {
  margin-bottom: 0;
}

.qr-code-img {
  box-shadow: 10px 1px 10px rgb(0, 0, 0);
  max-width: 20%;
  margin-top: 30px;
  border-radius: 5px;
}

.googleAndApple-btn-div {
  margin-top: 30px;
}

.download_app_photo_div {
  /* background-color: red; */
  display: flex;
  justify-content: right;
}

.bigger_i_first_div {
  position: absolute;
  margin-right: 290px;
  margin-top: 95px;
  cursor: pointer;
  z-index: 1;
  /* color: red; */
}
.download_app_photo {
  max-width: 70%;
  position: relative;
}

.bigger_i_div {
  position: absolute;
  margin-right: 175px;
  margin-top: 15px;
  z-index: 1;
  cursor: pointer;
  /* color: red; */
}
.biggerInSize-p-text-div p {
  font-size: 20px;
  font-weight: 500;
  color: rgb(80, 80, 80);
}

.biggerInSiz-cart-div {
  padding: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 75%;
  margin: auto;
}
.biggerInSiz-single-cart {
  background-color: #333533;
  padding: 30px;
  color: white;
}
.text-logo-flex-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  margin: auto;
  margin-bottom: 25px;
  margin-top: 20px;
}
.car-icon {
  font-size: 50px;
  color: rgba(0, 0, 26, 0.705);
}
.basic-header {
  font-size: 20px;
  font-weight: 500;
}
.biggerInSiz-p {
  color: rgb(155, 155, 155);
  margin-bottom: 20px;
}
.price-flex-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  margin: auto;
}
.price-title {
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
}
.price {
  font-size: 20px;
  font-weight: 700;
}
hr {
  width: 85%;
  margin: auto;
}
.order-button-one {
  background-color: rgba(0, 0, 26, 0.705);
  padding: 20px;
}
.order-text {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 50%;
  font-size: 18px;
  font-weight: 500;
}

/*  */

.second-car-logo {
  font-size: 50px;
  color: #333533af;
}
.order-button-two {
  padding: 20px;
  background-color: #333533af;
}
/*  */
.last-cart-parent-div {
  text-align: left;
  margin-left: 50px;
}
.last-cart-header {
  font-size: 30px;
  color: white;
  font-weight: 800;
}
.last-cart-p-one {
  margin-top: 25px;
  color: white;
}
.last-cart-p-two {
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
}
.media-parent-div {
  display: flex;
  align-items: center;
  gap: 30px;
  font-size: 35px;
  color: rgb(0, 117, 146);
}

/* Responsiveness start */

@media screen and (max-width: 1672px) {
  .bigger_i_first_div {
    margin-right: 275px;
    margin-top: 90px;
  }

  .bigger_i_div {
    margin-right: 160px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 1614px) {
  .bigger_i_first_div {
    margin-right: 265px;
    margin-top: 85px;
  }

  .bigger_i_div {
    margin-right: 155px;
    margin-top: 8px;
  }
}
@media screen and (max-width: 1562px) {
  .bigger_i_first_div {
    margin-right: 252px;
    margin-top: 81px;
  }

  .bigger_i_div {
    margin-right: 147px;
    margin-top: 7px;
  }
}
@media screen and (max-width: 1536px) {
  .i_div {
    gap: 40px;
  }
}
@media screen and (max-width: 1505px) {
  .bigger_i_first_div {
    margin-right: 242px;
    margin-top: 78px;
  }

  .bigger_i_div {
    margin-right: 142px;
    margin-top: 7px;
  }
}
@media screen and (max-width: 1465px) {
  .bigger_i_first_div {
    margin-right: 235px;
    margin-top: 75px;
  }

  .bigger_i_div {
    margin-right: 138px;
    margin-top: 7px;
  }
}
@media screen and (max-width: 1425px) {
  .bigger_i_first_div {
    margin-right: 226px;
    margin-top: 72px;
  }

  .bigger_i_div {
    margin-right: 132px;
    margin-top: 7px;
  }
}
@media screen and (max-width: 1375px) {
  .bigger_i_first_div {
    margin-right: 218px;
    margin-top: 68px;
  }

  .bigger_i_div {
    margin-right: 127px;
    margin-top: 5px;
  }
}
@media screen and (max-width: 1351px) {
  .bigger_i_first_div {
    margin-right: 212px;
    margin-top: 68px;
  }

  .bigger_i_div {
    margin-right: 123px;
    margin-top: 5px;
  }
}
@media screen and (max-width: 1350px) {
  .biggerINSize-text-div {
    gap: 50px;
  }
  .biggerInSize-p-text-div {
    justify-content: center;
  }
  .qr-code-img {
    width: 70px;
  }
  .i_div {
    gap: 20px;
  }
  .download_app_photo {
    max-width: 100%;
  }
  .bigger_i_first_div {
    margin-right: 285px;
    margin-top: 95px;
  }

  .bigger_i_div {
    margin-right: 168px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 1300px) {
  .bigger_i_first_div {
    margin-right: 275px;
    margin-top: 88px;
  }

  .bigger_i_div {
    margin-right: 160px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 1280px) {
  .biggerINSize-text-div {
    gap: 50px;
    max-width: 85%;
  }

  .biggerInSize-p-text-div {
    justify-content: center;
  }

  .googleAndApple-btn-div {
    display: flex;
  }

  .qr-code-img {
    width: 70px;
  }
  .i_div {
    gap: 20px;
  }
  .download_app_photo {
    max-width: 100%;
  }

  .bigger_i_first_div {
    margin-right: 330px;
    margin-top: 110px;
  }

  .bigger_i_div {
    margin-right: 195px;
    margin-top: 15px;
  }
}
@media screen and (max-width: 1235px) {
  .bigger_i_first_div {
    margin-right: 315px;
    margin-top: 105px;
  }

  .bigger_i_div {
    margin-right: 182px;
    margin-top: 15px;
  }
}
@media screen and (max-width: 1201px) {
  .bigger_i_first_div {
    margin-right: 305px;
    margin-top: 100px;
  }

  .bigger_i_div {
    margin-right: 177px;
    margin-top: 13px;
  }
}

@media screen and (max-width: 1200px) {
  .biggerINSize-text-div {
    grid-template-columns: repeat(1, 1fr);
    max-width: 100%;
  }

  .bigger_i_first_div {
    display: none;
  }

  .bigger_i_div {
    display: none;
  }
  .download_app_photo {
    display: none;
  }
  .download_tripShipTask_parent_div {
    text-align: center;
    width: 100%;
  }

  .Join_the {
    text-align: center;
  }
  .Connect_with {
    text-align: center;
  }

  .download_tripShipTask {
    text-align: center;
  }
  .googleAndApple-btn-div {
    display: block;
  }
  .app-btn {
    width: 150px;
  }
}
@media screen and (max-width: 950px) {
  .biggerInSize-p-text-div {
    align-items: center;
    margin: auto;
  }
}
@media screen and (max-width: 730px) {
  .Join_the {
    font-size: 23px;
  }
  .Connect_with {
    font-size: 23px;
  }

  .download_tripShipTask {
    font-size: 23px;
    font-weight: 500;
  }
}

@media screen and (max-width: 650px) {
  .googleAndApple-btn-div {
  }
  .i_div {
    display: none;
  }

  .qr-code-img {
    margin: auto;
    margin-top: 20px;
  }
}

@media screen and (max-width: 600px) {
  .qr-code-img {
    margin: auto;
    margin-top: 20px;
  }
}

@media screen and (max-width: 450px) {
  .googleAndApple-btn-div {
    display: grid;
    grid-template-columns: 1fr;
  }

  .Join_the {
    font-size: 20px;
  }
  .Connect_with {
    font-size: 20px;
  }

  .download_tripShipTask {
    font-size: 20px;

    font-weight: 500;
  }
}

@media screen and (max-width: 430px) {
  .download_tripShipTask_parent_div {
    margin-left: 0px;
  }
}
@media screen and (max-width: 414px) {
  .download_tripShipTask_parent_div {
    margin-left: 0px;
  }
}
@media screen and (max-width: 412px) {
  .download_tripShipTask_parent_div {
    margin-left: 0px;
  }
}
@media screen and (max-width: 400px) {
  .download_tripShipTask_parent_div {
    margin-left: -15px;
  }

  .Join_the {
    font-size: 18px;
  }
  .Connect_with {
    font-size: 18px;
  }

  .download_tripShipTask {
    font-size: 18px;

    font-weight: 500;
  }
}
@media screen and (max-width: 350px) {
  .download_tripShipTask_parent_div {
    margin-left: -40px;
  }
}
@media screen and (max-width: 320px) {
  .download_tripShipTask_parent_div {
    margin-left: -25px;
  }
}
@media screen and (max-width: 295px) {
  .download_tripShipTask_parent_div {
    margin-left: -60px;
  }
}
@media screen and (max-width: 280px) {
  .download_tripShipTask_parent_div {
    margin-left: -40px;
  }
}
@media screen and (max-width: 250px) {
  .download_tripShipTask_parent_div {
    margin-left: -65px;
  }
}
