/* .first_nav_div {
  background-color: #164692;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 6px;
} */
/* .nav_first_header_p {
  display: flex;
  align-items: center;
  gap: 10px;
} */

.navbar-main-parent-div {
  /* background-color: #f0000000; */
  /* background-color: rgba(255, 255, 255, 0.842); */
  background-color: #f8f8f8;
  height: 100px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 0;
  transition: background-color 0.2s ease;
}

.navbar-main-parent-div.scrolled {
  background-color: #e2e2e2;
  /* background-color: #eee; */
  background-color: white;
}
.navbar-parent-dvi {
  width: 82%;
  margin: auto;

  height: 100px;
}
.nav_li {
  font-weight: 600;
}
.mainLogo-img {
  max-width: 100%;
  max-height: 90px;
}

.nav-ul li {
  font-size: 16px;
}
.videos_link {
  margin-bottom: 20px;
}
.login-signUp-div {
  margin: auto;
  margin-left: 200px;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 300px;
}
.login-btn {
  /* background-color: #007a9d; */
  background-color: #c30017;
  color: white;
  width: 70%;
  padding: 7px;
  border-radius: 5px;
}
.signUp-btn {
  /* background-color: #007a9d; */
  background-color: #c30017;
  color: white;
  width: 70%;
  padding: 7px;
  border-radius: 5px;
}
.login {
  /* margin-right: 0px; */
  font-weight: 700;
  font-size: 15px;
}
.signUp {
  font-size: 14px;
  font-weight: 700;
  /* margin-right: 0px; */
}

.login-btn:hover {
  /* background-color: #014457; */
  background-color: #880111;
  transition: 0.5s;
}
.signUp-btn:hover {
  background-color: #014457;
  background-color: #880111;
  transition: 0.5s;
}

/* Responsiveness start */
@media screen and (max-width: 1500px) {
  .navbar-parent-dvi {
    width: 100%;
  }
}
@media screen and (max-width: 1250px) {
  .login-signUp-div {
    margin-left: 0;
  }
}
@media screen and (max-width: 1150px) {
  .login-signUp-div {
    width: 200px;
  }
  .login-btn {
    width: 30%;
  }
  .signUp-btn {
    width: 30%;
  }
}
@media screen and (max-width: 1024px) {
  .navbar-parent-dvi {
    width: 100%;
  }
  .login-signUp-div {
    display: flex;
    gap: 20px;
    margin-left: 30px;
  }
  .login-btn {
    width: 50%;
  }
  .signUp-btn {
    width: 50%;
  }
}
@media screen and (max-width: 1023px) {
  .login-signUp-div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin-left: 30px;
  }
}

@media screen and (max-width: 912px) {
  .login-btn {
    width: 50%;
    margin-left: -30px;
  }
  .signUp-btn {
    width: 50%;
    margin-left: -30px;
  }
}
@media screen and (max-width: 820px) {
  .login-btn {
    width: 50%;
    margin-left: -30px;
  }
  .signUp-btn {
    width: 50%;
    margin-left: -30px;
  }
}
@media screen and (max-width: 800px) {
  .login-btn {
    width: 50%;
    margin-left: -30px;
  }
  .signUp-btn {
    width: 50%;
    margin-left: -30px;
  }
}

@media screen and (max-width: 712px) {
  .login-btn {
    width: 50%;
    margin-left: -30px;
  }
  .signUp-btn {
    width: 50%;
    margin-left: -30px;
  }
}

@media screen and (max-width: 600px) {
  .login-btn {
    width: 50%;
    margin-left: -30px;
  }
  .signUp-btn {
    width: 50%;
    margin-left: -30px;
  }
}

@media screen and (max-width: 540px) {
  .login-signUp-div {
  }
  .navbar-parent-dvi {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  .login-btn {
    width: 50%;
    margin-left: -30px;
  }
  .signUp-btn {
    width: 50%;
    margin-left: -30px;
  }
}
@media screen and (max-width: 320px) {
  .signUp {
    font-size: 14px;
  }
  .login {
    margin-right: 7px;
    font-size: 14px;
  }
}
