.ConnectAssistEarn-parent-div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 35%;
  margin: auto;
  background-color: white;
  box-shadow: 10px 10px 100px rgb(197, 197, 197);
  padding: 40px;
  margin-bottom: -100px;
  margin-top: 40px;
  border-radius: 10px;
  font-family: "Michroma", sans-serif;
  position: relative;
  z-index: 1;
  height: 105px;
}
.ConnectAssistEarn-parent-div h3 {
  font-size: 18px;
  font-weight: 700;
}
.ConnectAssistEarn-parent-div p {
  color: #5d6467;
  font-weight: 500;
  font-size: 18px;
}

@media screen and (max-width: 1250px) {
  .ConnectAssistEarn-parent-div {
    width: 70%;
  }
}
@media screen and (max-width: 750px) {
  .ConnectAssistEarn-parent-div {
    width: 80%;
  }
}
@media screen and (max-width: 640px) {
  .ConnectAssistEarn-parent-div {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    width: 100%;

    height: 100%;
  }
}
@media screen and (max-width: 400px) {
  .ConnectAssistEarn-parent-div {
    width: 100%;
  }
}
