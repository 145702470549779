.HowToLogin_heading {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: -950px;
  font-size: 22px;
  font-weight: 500;
}
.HowToLogin_video {
  width: 560px;
  height: 315px;
  margin-left: -800px;
  margin-bottom: 50px;
}

@media screen and (max-width: 1210px) {
  .HowToLogin_heading {
    margin-left: -880px;
    font-size: 21px;
  }
  .HowToLogin_video {
    /* width: 560px;
    height: 315px; */
    margin-left: -700px;
  }
}
@media screen and (max-width: 1160px) {
  .HowToLogin_heading {
    margin-left: -830px;
    font-size: 20px;
  }
  .HowToLogin_video {
    /* width: 560px;
    height: 315px; */
    margin-left: -700px;
  }
}
@media screen and (max-width: 1115px) {
  .HowToLogin_heading {
    margin-left: -800px;
    font-size: 19px;
  }
  .HowToLogin_video {
    /* width: 560px;
    height: 315px; */
    margin-left: -650px;
  }
}
@media screen and (max-width: 1115px) {
  .HowToLogin_heading {
    margin-left: -750px;
    font-size: 18px;
  }
  .HowToLogin_video {
    /* width: 560px;
    height: 315px; */
    margin-left: -620px;
  }
}
@media screen and (max-width: 1035px) {
  .HowToLogin_heading {
    margin-left: -700px;
    font-size: 17px;
  }
  .HowToLogin_video {
    width: 500px;
    height: 315px;
    margin-left: -600px;
  }
}
@media screen and (max-width: 990px) {
  .HowToLogin_heading {
    margin-left: -660px;
    font-size: 16px;
  }
  .HowToLogin_video {
    width: 450px;
    height: 315px;
    margin-left: -550px;
  }
}
@media screen and (max-width: 954px) {
  .HowToLogin_heading {
    margin-left: -600px;
    font-size: 15px;
  }
  .HowToLogin_video {
    width: 450px;
    height: 315px;
    margin-left: -500px;
  }
}
@media screen and (max-width: 905px) {
  .HowToLogin_heading {
    margin-left: -550px;
    font-size: 14px;
  }
}

@media screen and (max-width: 860px) {
  .HowToLogin_heading {
    margin-left: -500px;
    font-size: 13px;
  }
  .HowToLogin_video {
    width: 400px;
    height: 315px;
    margin-left: -450px;
  }
}
@media screen and (max-width: 815px) {
  .HowToLogin_heading {
    margin-left: -500px;
    font-size: 12px;
  }
}
@media screen and (max-width: 800px) {
  .HowToLogin_heading {
    display: none;
  }
  .HowToLogin_video {
    width: 370px;
    height: 300px;
    margin-top: 50px;
    margin-left: -400px;
  }
}
@media screen and (max-width: 725px) {
  .HowToLogin_video {
    width: 300px;
    height: 200px;

    margin-left: -350px;
  }
}
@media screen and (max-width: 675px) {
  .HowToLogin_video {
    margin-left: -320px;
  }
}
@media screen and (max-width: 640px) {
  .HowToLogin_video {
    width: 270px;
    height: 200px;
    margin-left: -280px;
  }
}
@media screen and (max-width: 560px) {
  .HowToLogin_video {
    width: 240px;
    height: 200px;
    margin-left: -250px;
  }
}
@media screen and (max-width: 500px) {
  .HowToLogin_video {
    width: 220px;
    height: 190px;
    margin-left: -230px;
  }
}
@media screen and (max-width: 450px) {
  .HowToLogin_video {
    width: 200px;
    height: 190px;
    margin-left: 50px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 400px) {
  .HowToLogin_video {
    margin-left: 20px;
  }
}
@media screen and (max-width: 400px) {
  .HowToLogin_video {
    width: 180px;
    height: 150px;
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 350px) {
  .HowToLogin_video {
    width: 180px;
    height: 150px;
    margin-left: 10px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 280px) {
  .HowToLogin_video {
    width: 140px;
    height: 120px;
    margin-left: 0px;
  }
}
