.ourAchievment-parent-div {
  padding: 35px;
  color: black;
  max-width: 85%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  border-radius: 20px;
}

.ourAchievment-p {
  max-width: 80%;
  margin: auto;
  margin-top: 20px;
  font-size: 16px;
}
.ourAchievement-heading {
  font-size: 18px;
  font-weight: 700;
  color: black;
}
.ourAchievment-p-div {
  text-align: left;
}

@media screen and (max-width: 1024px) {
  .ourAchievment-parent-div {
    max-width: 100%;
    border-radius: 0px;
  }
  .ourAchievment-p {
    max-width: 100%;
  }
}
@media screen and (max-width: 700px) {
  .ourAchievment-parent-div {
    max-width: 100%;
    border-radius: 0px;
  }

  .ourAchievment-p {
    max-width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .ourAchievment-p {
    max-width: 100%;
  }
}

@media screen and (max-width: 360px) {
  .ourAchievment-logo-div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
    margin-bottom: 0;
  }
  .ourAchievment-number-k {
    margin-top: 10px;
  }
}
